<template>
  <b-section-scroll
    title="成绩单"
    @more="$router.push('/news')"
  >
    <div
      v-if="list.length === 0"
      class="g-empty"
    ></div>
    <div
      class="item"
      v-for="item in list"
      :key="item.id"
      @click="onNav(item)"
    >
      <div class="name">
        {{ item.title }}
      </div>
      <!-- <div>dept</div> -->
      <div class="info">
        <span class="g-mr-5">{{ item.user.department.name }}</span>
        <span class="g-mr-5">{{ item.user.name }}</span>
        <span class="g-mr-5">发布于</span>
        <span>{{ item.created_at }}</span>
      </div>
    </div>
  </b-section-scroll>
</template>

<script>
import { getNewsPage } from '@/api/news/index'

export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.fetchList()
  },
  computed: {
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  methods: {
    fetchList() {
      getNewsPage(2, { department_id: this.deptId }).then(res => {
        this.list = res.data
      })
    },
    onNav(row) {
      this.$router.push({
        path: '/news/info',
        query: { id: row.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  padding: 15px;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 10px;

  + .item {
    margin-top: 15px;
  }

  &:hover {
    cursor: pointer;
    background: rgba($color: #dfe3e8, $alpha: 0.4);
  }

  .name {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #2b2d42;
  }

  .info {
    font-size: 12px;
    color: #747e8f;
  }
}
</style>
